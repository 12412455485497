@import url("https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100;300;400;500;600;700;800;900&display=swap");

* {
    font-family: "Noto Kufi Arabic", sans-serif;
    box-sizing: border-box;
    /* -webkit-user-select: none; */
    /* user-select: none; */
}

body {
    padding: 0;
    margin: 0px;
}
.inline-block{
    display: inline-block;
}
p {
    padding: 0;
    margin: 0;
    margin-bottom: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

.relative {
    position: relative;
}


.text-white {
    color: #fff;
}

.text-green {
    color: #6bbe99;
}

.pointer {
    cursor: pointer;
}

.underline {
    text-decoration: underline;
}

.py-5 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.my-5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.my-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.absolute {
    position: absolute;
}

hr {
    border-color: #dddddd40;

}

.bg-body-tertiary {
    background-color: #22225d !important;
    z-index: 5000;

}
.bg-black{
    background-color: black;
}
.bg-orange{
    /* background-color: #d49f00 !important; */
    /* background-color: #b6bf00 !important; */
    background-color: #f8f8f8 !important;


}
.navbar-item.bg-orange{
    color: #22225d !important;
}
.app-wrapper{
    max-width: 1280px;
    margin: auto;
}

.navbar {
    background-color: #22225d !important;
    position: fixed;
    width: 100%;
    z-index: 5000;

}

.navbar * {
    color: #fff !important;

}

.form-container {
    padding: 20px;
}

.form-label {
    margin-bottom: 0px !important;
    font-size: 12px;
}
.awssld__controls{
    display: none;
}