.filter-page-cover .cover-content {
    width: 100%;
    min-height: 350px;
}
.cover-content video {
    object-fit: cover;
    width: 100%;
    object-fit: cover;
    filter: brightness(.5);
    margin-top: -550px;
}
.cfilter-controls {
    min-height: 120px;
    width: 80%;
    margin: auto;
    background-color: #f5f5f5;
    margin-top: -60px;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: grid;
    align-items: center;
    padding: 20px;

}
.cfilter-controls input{
    min-height: 50px;
}
.cfilter-controls .ant-form{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    align-items:last baseline;
    gap: 15px;
}
.cfilter-controls button{
    min-height: 50px;
    width: 100%;
    background-color: #00102e;
    color: #fff;
}