.container>div>div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  pointer-events: none;
  justify-content: start !important;
  font-family: 'Kanit', sans-serif;
  line-height: 0px;
  text-transform: uppercase;
}

.number {
  font-size: 300px;
  color: #545864;
}

.number span {
  display: inline-block;
  position: relative;
}

.slopeBegin {
  background-color: #20232f;
  clip-path: polygon(20% 0, 70% 0, 50% 100%, 0% 100%);
}

.slopeEnd {
  clip-path: polygon(70% 0, 100% 0, 80% 100%, 50% 100%);
}

.slopeBegin,
.slopeEnd {
  position: absolute;
  width: 170%;
  height: 100%;
  cursor: pointer;
}

.slider-slide {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat !important;
}

.awssld__content {
  /* filter: brightness(.7) blur(2px); */
}

.pink {
  background: linear-gradient(to right, deeppink 0%, coral 100%);
}

.teal {
  background: linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%);
}

.tomato {
  background: linear-gradient(to right, tomato 0%, gold 100%);
}

.awssld__content div {
  font-size: 5rem;
  color: #fff;
}




/* ------------------------------------------------animation-------------------------- */
.awssld--cubeAnimation {
  --cube-animation-duration: 675ms;
  --cube-animation-perspective: 2800px;
  --cube-animation-ease-in: cubic-bezier(0.8, 0, 1, 0.8);
  --cube-animation-ease-out: cubic-bezier(0, 0.2, 0.2, 1);
  --cube-translateZ-distance: -225px;
}

.awssld--cubeAnimation .awssld__container {
  perspective: var(--cube-animation-perspective);
}

.awssld--cubeAnimation .awssld--moveRight {
  transform-origin: 0% 50%;
  animation: cubeLeftIn var(--cube-animation-duration) both var(--cube-animation-ease-in);
}

.awssld--cubeAnimation .awssld--moveLeft {
  transform-origin: 100% 50%;
  animation: cubeRightIn var(--cube-animation-duration) both var(--cube-animation-ease-in);
}

.awssld--cubeAnimation .awssld--exit.awssld--moveLeft {
  transform-origin: 100% 50%;
  animation: cubeLeftOut var(--cube-animation-duration) both var(--cube-animation-ease-in);
}

.awssld--cubeAnimation .awssld--exit.awssld--moveRight {
  transform-origin: 0% 50%;
  animation: cubeRightOut var(--cube-animation-duration) both var(--cube-animation-ease-in);
}

@keyframes cubeLeftOut {
  50% {
    animation-timing-function: var(--cube-animation-ease-out);
    transform: translateX(-50%) translateZ(var(--cube-translateZ-distance)) rotateY(-45deg);
  }

  100% {
    transform: translateX(-100%) rotateY(-90deg);
  }
}


@keyframes cubeRightOut {
  50% {
    animation-timing-function: var(--cube-animation-ease-out);
    transform: translateX(50%) translateZ(var(--cube-translateZ-distance)) rotateY(45deg);
  }

  100% {
    transform: translateX(100%) rotateY(90deg);
  }
}


@keyframes cubeLeftIn {
  0% {
    transform: translateX(100%) rotateY(90deg);
  }

  50% {
    animation-timing-function: var(--cube-animation-ease-out);
    transform: translateX(50%) translateZ(var(--cube-translateZ-distance)) rotateY(45deg);
  }
}


@keyframes cubeRightIn {
  0% {
    transform: translateX(-100%) rotateY(-90deg);
  }

  50% {
    animation-timing-function: var(--cube-animation-ease-out);
    transform: translateX(-50%) translateZ(var(--cube-translateZ-distance)) rotateY(-45deg);
  }
}
