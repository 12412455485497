.navigation-menu {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    background: transparent;
    transition: all 250ms ease-in-out;


}

.navigation-wrapper {
    min-height: 80px;
    padding: 0 20px;
}
p.logo-place a{
    color: unset;
    text-decoration: none;
}

.navigation-menu.scrolled {
    background-color: #ececec;
    box-shadow: 0px 4px 20px #00000094;
}

.nav-item {
    font-size: 1.5rem;
    color: #fff;
    /* border-radius: 8px; */
    font-size: 1.2rem;
    color: #fff;
    padding: 5px 10px;
    text-align: center;
}
.nav-item.bg-orange{
    /* color: #22225d !important; */
    
}
.myportal{
    background-color: #000 !important;
    color: #fff !important;
}
.logo-place {
    font-size: 2.5rem;
    color: #fff;
}

.navigation-menu.scrolled .logo-place,.navigation-menu.scrolled .nav-item {
    color: #000;
}
.nav-item p{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 10;

}

