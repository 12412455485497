


.auth-page, .awssld--cubeAnimation .awssld__container{
    /* width: 100vw;
    height: 100vh;
    overflow: hidden; */
    
}
.auth-page .sliderr-wrapper{
    /* filter: brightness(.25); */
}
.auth-page .auth-form{
    top: 40px;
    right:40px;
    background-color: #fff;
    min-width: 450px;
    padding: 30px 40px;
    border-radius: 10px;
}
.auth-btns{
    display: grid;
    gap:10px;
    grid-template-columns: repeat(3,1fr);
}
.auth-btns button{
    padding:3px;
    border-radius: 3px;
    border:none;
    box-shadow: none;
    outline: none;
    font-size: 24px;

}
.auth-btns button:nth-child(2){
    color:#1877F2;
}
.authenticate-btn{
    background-color: #032d57;
    border:none;
    outline:0;
    box-shadow: none;
    width:100%;
    color:#fff;
    margin: 10px auto 5px;
}
.authenticate-btn:hover,.authenticate-btn:focus{
    background-color: #021a31 !important;
    color: #fff !important;
}
.auth-form .ant-form-item {
    margin-bottom: 5px !important;
}
.auth-form :where(.css-dev-only-do-not-override-1okl62o).ant-input-lg {
min-height: 42px !important;
}
.form-tail{
    text-align: center;
    margin-top: 12px;
}
.form-tail p{
margin: 0;
padding: 0;
}
.auth-form .ant-form-item-label label{
    font-size: 12px !important;
    height: 37px;
}