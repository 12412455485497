.final-homepage {
    min-height: 120vh;
}

.hero-section {
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.hero-section video {
    position: absolute;
    top: 50%;
    left: 50%;
    /* width: 100vw; */
    /* Full viewport width */
    /* height: 100vh; */
    /* Full viewport height */
    object-fit: cover;
    object-position: top 80px;
    transform: translate(-50%, -50%);
    z-index: -1;
    filter: brightness(.5);
}

.hero-desc{
    position: absolute;
    top: 50%;
    color: #fff;
    left: 60%;
    transform: translate(-65%, -50%);
    width: 100%;

}

.hero-desc .main{
    font-size: 2rem;
    font-weight: 900;
}
.now-btn{
    background-color: #b6bf00;
    color:#fff;
    padding: 10px;
    width: 40%;
    display: block;
    margin: 20px 0;
    text-align: center;

}
/* main seaction styles */

.website-sections-outer .sections {
    display: flex;
    height: 90vh;
    /* height: 450px; */
    overflow: hidden;
}

.website-sections-outer .sections .app-section {
    flex: 1;
    height: 100%;
    background-position: center;
    background-size:auto;
    background-repeat: no-repeat;
    transition: all 0.7s cubic-bezier(0.65, 0, 0.35, 1); 
    filter: brightness(.3);
    cursor: pointer;
    position: relative;
    will-change:flex ;
}
.website-sections-outer .sections .app-section:hover {
    flex: 2.5;
    filter: brightness(.6);
}

.website-sections-outer .sections .app-section:not(:hover) {
    flex: 1;
    transition: all 0.7s cubic-bezier(0.65, 0, 0.35, 1); 
    filter: brightness(.3);

}
.app-section .secion-text{
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0,-50%);
    text-align: center;
}

.secion-text p{
    font-size: 1.6rem;
    color: #000;
    font-weight: 900;
    transition: all 500ms ease-in-out;
}
.secion-text button{
    background-color: transparent;
    color: #000;
    width: 60%;
    padding:10px;
    margin-top: 25px;
    transition: all 350ms ease-in-out ;

}

.app-section:hover .secion-text p{
    font-size: 5rem;
    
}
.app-section:nth-of-type(odd) .secion-text p{
    color: #fff;
}
.app-section:hover button{
    background-color: #fff;
}
.app-section img{
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
}
.hero-section,
.inner-wrapper{
    max-width:2000px;
    margin: auto;
}

/* end of main sections styeles */