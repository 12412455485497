.applications-services-section {
    min-height: 200px;
    padding: 50px 0;
    /* max-width: 1300px; */
    margin: auto;
    background-color: #f5f5f5;
}

.services-title {
    font-size: 2.5rem;
    font-weight: 600;
    background: linear-gradient(118deg, #49379a 0, #44c0ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    padding: 5px 50px;
    -webkit-text-fill-color: transparent;
    ;
}

.slide-img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* width:300px; */
    padding-top: 80%;
}

.swiper-content {
    margin-top: 30px;
}

.slide-inner-content {
    display: grid;
    grid-template-columns: 2fr 1.2fr;
    background: #fff;
    /* border:1px solid #ddd; */
    min-height: 450px;

}

.vacancy-name {
    font-size: 1.5rem;
    font-weight: 900;
    background: linear-gradient(118deg, #49379a 0, #00a9ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.slide-text {
    padding: 20px;
    position: relative;
}

.vacancy-desc {
    padding: 30px 0;
    font-size: 18px;
}

.slide-text button.service-btn {
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
}

.service-btn {

    padding: 10px 30px;
    display: block;
    margin: 50px auto;

    border: 1px solid #000;
    background: transparent;
    transition: all 200ms ease-in-out;
    color: #000;

    /* background-color: ; */

}

 button.service-btn:hover {
    background: linear-gradient(118deg, #49379a 0, #00a9ff 100%);
    color: #fff;
    border-color: transparent;


}


.service-btn.border-gradient {
    border-width: 3px;
}