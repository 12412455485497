.profile-cover {
  min-height: 350px;
  background-color: #f1f1f1;
  background-position: center;
  background-size: cover;
}

.profile-btns {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 15px;
}

.profile-btns button{
    color: #000;
    border-color: transparent;
    padding: 8px;
    border-radius: 5px;
    font-size: 16px;
    /* font-weight: 600; */
    background-color: #ededed;
}
.profile-btns button.apply-now{
    background-color: #050b4d;
    color: #fff;

}
.profile-head{
    display: grid;
    grid-template-columns: auto auto;
    align-items:center;
    margin-top: -60px;
}
.profile-head .avatar{
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-color: #ededed;
    background-position: center;
    background-size: cover;

    border: 1px solid #fff;
}
.profile-container{
    max-width: 1300px;
    margin: auto;
}
.ant-tabs-tab-btn{
    font-size: 16px;
    font-weight: 600;
}

.profile-left{
    display: grid;
    grid-template-columns: auto auto;
    gap: 8px;
    align-items: center;
    justify-content: start;
    position: relative;
    right: 50px;
}
.profile-left p.name{
    font-size: 2rem;
    font-weight: 600;
}
.profile-left p{
    margin: 0;
}
.profile-text{
margin-top: 40px;
}
.profile-container :where(.css-dev-only-do-not-override-17seli4).ant-tabs >.ant-tabs-nav .ant-tabs-nav-list, :where(.css-dev-only-do-not-override-17seli4).ant-tabs >div>.ant-tabs-nav .ant-tabs-nav-list{
    gap: 70px !important;
}
.profile-container :where(.css-dev-only-do-not-override-17seli4).ant-tabs .ant-tabs-tab+.ant-tabs-tab{
    margin: 0 !important;
}

.profile-container .ant-tabs .ant-tabs-tab+.ant-tabs-tab{
    margin: 0 !important;
    gap: 70px !important;


}
.ant-tabs-nav-list{
    gap: 40px;
}