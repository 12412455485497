#application-form {
    background-color: #fff;
}

.application-header {
    /* padding: 0 20px; */
}

.header-content {
    box-shadow: 0px -4px 13px 3px #0000002b;
    min-height: 150px;
    background-color: #fff;
    padding: 15px 0 0;

    /* padding: 20px 80px; */

}

.header-grid {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: last baseline;

}

.header-grid a {
    text-decoration: unset !important;
    color: #000 !important;
    /* s */
}

.app-wrapper {
    max-width: 1300px;
    margin: auto;
}

.application-logo img {
    width: 60px;
    margin-left: 10px;
}

.application-steps {
    max-width: 1300px;
    margin: auto;
    padding: 20px 0;

}

.steppers-bars {
    display: grid;
    grid-template-columns: repeat(auto-fit, 24%);
    gap: 1%;
}

#application-form .ant-progress-text {
    display: none;
}

.stepper-desc {
    margin-top: 10px;
}

.stepper-desc p {
    color: #8d8d8d;
    font-size: 14px;
}

p.application-header-title {
    font-size: 20px;
    color: #000;
    font-weight: 600;
}

.steps-header p.pages {
    font-weight: 500;
    font-size: 19px;
    font-weight: 600;
    color: #666666;
    margin-bottom: 33px !important;
}

.application-form-blocks {
    margin-top: 100px;
}

.form-block {
    min-height: 200px;
    border-radius: 20px;
    border: 1px solid #a3a3a3;
    margin-bottom: 50px;
    position: relative;
    padding-top: 40px;
}

.children-block {
    border: 5px solid #000091;
}

.form-block-name {
    position: absolute;
    background-color: #fff;
    padding: 13px 13px;
    top: -34px;
    right: 80px;
    font-size: 26px;
    font-weight: 600;
    color: #000;

}

.form-fields {
    padding: 15px 90px 50px;
}

.cform-label {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 15px;
}

.application-form-blocks .form-fields input {
    /* width: 50%; */
    height: 60px;

}

.form-fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
}

.next-flow {
    background-color: #000091;
    padding: 10px 20px;
    color: #fff;
    width: 250px;
    margin-right: auto;
    display: block;
    position: relative;
}
.next-flow span.icon{
    position: absolute;
    right: 20px;
}


.ant-divider {
    width: 70% !important;
    margin: auto;
    min-width: unset !important;
}

.child-info:last-child .ant-divider {
    display: none !important;
}

.add-child {
    text-align: center;
    color: #000091;
    font-weight: 600;
    padding: 15px 0;
    cursor: pointer;
    text-decoration: underline;
}

.upload-wedgit {
    width: 150px;
    min-height: 150px;
    margin: auto;
    background-color: #efefef;
    border-radius: 6px;
    position: relative;
    border: 1.5px dashed #aaaaaa;
}

.docs {
    display: grid;
    grid-template-columns: repeat(auto-fit, 150px);
    gap: 23px;
    justify-content: center;
}

.docs> div.img {
    height: 150px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
    filter: brightness(.8);
}

.upload-wedgit input.absolute {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    position: absolute;
}

.upload-icon.absolute {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5rem;
    color: #c1c1c1;
}

.cform-label.text-center {
    text-align: center;
    margin: 15px;
}