.footer-grid {
    min-height: 300px;
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.footer-grid .dark-section {
    background-color: #0e082b;
    color: #fff;
    text-align: center;
    padding: 50px 15px;

}

.footer-grid .light-section {
    background-color: #3ba6dd;
    color: #fff;
    text-align: center;
    padding: 20px;
}

.contactus-btn {
    background-color: #fff;
    border: none;
    color: #000;
}

.light-section p.footer-ad {
    font-size: 22px;
}

.light-section h5 {
    margin: 40px 0;
    /* font-weight: ; */
}

.light-section button {
    padding: 12px 25px;
}

.dark-section .footer-name{
    font-size: 1.5rem;
    margin-bottom: 25px !important;
    font-weight: 500 !important;
}
.socials {
    display: grid;
    font-size: 1.8rem;
    grid-template-columns: repeat(4, 35px);
    gap: 10px;
    justify-content: space-between;
}
.links-cols{
    display: grid;
    grid-template-columns: 200px 1fr 1fr 1fr;
    font-size: 16px;
    justify-content: space-between;
}
.links-cols p{
    font-weight: 100 !important;

}