.flow-wrapper {
    margin: auto;
    min-height: 100vh;
    background-color: #f8f8f8;
}

.installment-step {
    margin: -100px auto;
    background-color: #fff;
    padding: 25px;
    border-radius: 10px;
    margin-bottom: 100px;
}

.installment-step button {
    width: 100%;
}

.installment-step .ant-form-item-label {
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 0;
}

.installment-step .ant-form-item {
    margin-bottom: 15px;
}

.flow-hero {
    background-color: #202359;
    min-height: 240px;
}

.flow-wrapper .inner-flow {
    max-width: 650px;
    margin: auto;

}

.flow-next-btn {
    background: #81E29F !important;
    border-color: #81E29F !important;
    margin-top: 30px;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-input:placeholder-shown {
    font-size: 13px;
    font-weight: 600;
    font-family: "Noto Kufi Arabic", sans-serif;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-input-lg {
    min-height: 50px;
}

.ant-select-selector {
    min-height: 50px;
}

.form-title {
    text-align: center;
}

.form-title span {
    margin-bottom: 0;
    /* color: #8d8d8d; */
    font-size: 14px;
}

.form-title p {
    color: #5C63FB;
    font-size: 27px;
    font-weight: 600;
}

.flow-header {
    width: 500px;
    margin: auto;
    color: #000;
    padding-top: 20px;
    text-align: center;
}

.flow-header>* {
    margin-bottom: 2px;
    padding: 0;
    color: #fff;

}

.flow-header .first {
    font-size: 16px;

}

.flow-header .second {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;

}

.flow-header .third {
    color: #5bf074;
    font-size: 14px;
}

.error {
    font-size: 12px;
    font-weight: 400;

}

.red {
    color: rgb(240, 3, 3);
}

.selected-school {
    padding: 15px;
    font-size: 15px;
    background: #dddddd42;
    border-radius: 7px;
    margin-bottom: 20px;
}

.selected-school img {
    border-radius: 50%;
    vertical-align: text-bottom;
}

.child-row {
    margin-bottom: 10px;
}
.child-row:not(:last-child) {
    border-bottom: 3px solid #5C63FB;
}
.change-btn {
    left: 17px;
    top: 15px;
    font-size: 13px;
    padding: 6px 7px;
    border-radius: 4px;
    color: #5C63FB;
    border: 1px solid #5C63FB;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;   
}

.change-btn:hover {
    color: #fff;
    background-color: #5C63FB;
    border-color: #5C63FB;
}
p.fees-step-value{
    background: linear-gradient(to left, #5F60CE, #76D5B2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    font-size: 60px !important;
}
p.fees-step-value span{
    font-size: 30px;
}
p.fees-installment{
    color:#8d8d8d;
    font-size: 12px;
}
p.fees-installment span:first-child{
    color: #5F60CE;
    font-weight: bold;
    margin: 0 5px;
}
p.fees-installment span{
    padding: 0 5px;
}
p.fees-installment span:last-child{
    color: #76D5B2;
    font-weight: bold;
    margin: 0 5px;
}
.fees-step p{
    margin-bottom: 3px !important;
}
p.indicate{
    /* margin: 1rem; */
    font-size: 12px !important;
    color: rgb(33, 150, 243);
}

@media only screen and (max-width: 767px) {
    .flow-header {
        max-width: 200px;
        height:280px;
    }
}